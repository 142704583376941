<template>
  <div
    class="absolute bottom-0 ml-6 h-1.5 w-7 px-2 transition lg:px-5"
    :style="`transform: translateX( ${underlineTranslateX}px);  width: ${underlineWidth}px;`"
    :class="[`${underlineColor || 'blue'}-el`]"
  >
    <div
      class="h-full w-full bg-blue-500 transition-colors green-el:bg-green-500 pink-el:bg-pink-500"
    />
  </div>
</template>

<script setup>
const props = defineProps({
  nav: { type: Object, default: null },
  elements: { type: Object, default: null },
  logo: { type: Object, default: null },
  hoverindex: { type: Number, default: null },
  open: { type: Boolean, default: null },
  activeindex: { type: [Number, Boolean], default: false },
})

const underlineTranslateX = ref(0)
const underlineWidth = ref(0)
const underlineColor = ref(null)

//calculate width, translation and color
watch(
  () => [props.hoverindex, props.open, props.activeindex, props.logo],
  () => {
    if (!process.client) return
    underlineTranslateX.value = 0
    //if close calc with activeindex and if open calc with hoverindex
    let indexOfUnderline = props.open ? props.hoverindex : props.activeindex

    //add up clientWidths form element until indexOfUnderline
    for (let i = 0; i < indexOfUnderline; i++) {
      underlineTranslateX.value += props.elements[i].clientWidth
    }
    underlineTranslateX.value += props.logo?.clientWidth
    underlineWidth.value = props.elements[indexOfUnderline]?.clientWidth
    underlineColor.value =
      props.nav.tree[indexOfUnderline]?.page?.color_theme?.value
  }
)
</script>
